import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { Track } from '@interfaces/track.model';
import { FirebaseService } from '@services/firebase.service';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  playFunction: (track?: Track) => void = null;
  playerElement: ElementRef;
  // Dynamic update of playlist
  tracks: Track[] = [];
  playlistSubject$: BehaviorSubject<Track[]> = new BehaviorSubject<Track[]>(this.tracks);
  // Get the current track
  currentTrack: Track = null;
  currentTrackSubject$: BehaviorSubject<Track> = new BehaviorSubject<Track>(this.currentTrack);
  // Get the current time
  currentTime: number = null;
  currentTimeSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(this.currentTime);
  currentTrackDuration = 0.01;
  constructor(private firebaseService: FirebaseService) {
  }
  get audioPlayer(): HTMLAudioElement {
    return this.playerElement.nativeElement as HTMLAudioElement;
  }
  get playing$(): Observable<Event> {
    return fromEvent(this.audioPlayer, 'playing');
  }
  get paused$(): Observable<Event> {
    return fromEvent(this.audioPlayer, 'pause');
  }
  setPlaylist(tracks: Track[]): void {
    this.tracks = tracks;
    this.playlistSubject$.next(this.tracks);
  }
  getPlaylist(): Observable<Track[]> {
    return this.playlistSubject$.asObservable();
  }
  setCurrentTrack(currentTrack: Track): void {
    this.currentTrack = currentTrack;
    this.currentTrackSubject$.next(this.currentTrack);
  }
  getCurrentTrack(): Observable<Track> {
    return this.currentTrackSubject$.asObservable();
  }
  setCurrentTime(currentTime: number): void {
    this.currentTime = currentTime;
    this.currentTimeSubject$.next(this.currentTime);
  }
  getCurrentTime(): Observable<number> {
    return this.currentTimeSubject$.asObservable();
  }
  pause(): void {
    this.audioPlayer.pause();
  }
  play(audio: Track): void {
    this.firebaseService.callCloudFunction('incrementNasheedPlayCounter', audio.nasheedId).subscribe();
    this.setPlaylist([audio]);
  }
  seek(seconds: number): void {
    this.audioPlayer.currentTime = seconds;
  }
  resume(): void {
    this.audioPlayer.play().catch(console.warn);
  }
  addToPlaylist(audio: Track): void {
    console.warn('Playlist feature is disabled.', audio);
    return;
    // // const existingPlaylist: Track[] = this.tracks;
    // existingPlaylist.push(audio);
    // this.setPlaylist(existingPlaylist);
  }
}
